<template>
  <div class="transport-page">
    <div class="map" ref="map"></div>

    <left @cars-location="carsLocation" @cut-car="cutCar"></left>

    <div class="date-box" v-if="searchShow">
      <DatePicker
        v-model="beginTime"
        type="date"
        placeholder="请选择开始时间"
        style="width: 200px"
        :options="beginOption"
      ></DatePicker>
      <span style="margin: 0 5px">~</span>
      <DatePicker
        v-model="endTime"
        type="date"
        placeholder="请选择结束时间"
        style="width: 200px"
        :options="endOption"
      ></DatePicker>
      <Button type="primary" style="margin-left: 15px" @click="search"
        >查询</Button
      >
      <Button
        type="primary"
        style="margin-left: 15px"
        v-if="trajectory"
        @click="playAni"
        >播放</Button
      >
      <Button
        type="primary"
        style="margin-left: 15px"
        v-if="trajectory"
        @click="pauseAni"
        >暂停</Button
      >
      <Button
        type="primary"
        style="margin-left: 15px"
        v-if="trajectory"
        @click="back"
        >返回</Button
      >
    </div>
  </div>
</template>

<script>
import left from "./left";
import moment from "moment";
export default {
  name: "",
  components: {
    left,
  },
  data() {
    return {
      beginOption: {
        disabledDate: (date) => {
          if (!this.endTime) return false;
          if (
            moment(date).isBefore(moment(this.endTime).subtract(2, "days")) ||
            moment(date).isAfter(moment(this.endTime))
          )
            return true;
          return false;
        },
      },
      endOption: {
        disabledDate: (date) => {
          if (!this.beginTime) return false;
          if (
            moment(date).isBefore(moment(this.beginTime)) ||
            moment(date).isAfter(moment(this.beginTime).add(2, "days"))
          )
            return true;
          return false;
        },
      },
      map: null,
      siteMap: new Map(),
      searchShow: false,
      currentCar: null,
      beginTime: "",
      endTime: "",
      trajectory: null,
      trajectoryPoints: [],
      startMarker: null,
      endMarker: null,
      trackAni: null,
    };
  },
  methods: {
    initMap() {
      this.map = new BMapGL.Map(this.$refs.map);
      let point = new BMapGL.Point(105.52314042411466, 29.475651579673745);
      this.map.centerAndZoom(point, 11);
      this.map.enableScrollWheelZoom();
      this.map.disablePinchToZoom();
    },
    carsLocation(cars) {
      if (cars.length == 0) return;
      for (let i = 0; i < cars.length; i++) {
        let car = cars[i];
        let point = new BMapGL.Point(car.lng, car.lat);
        if (this.siteMap.has(car.carId)) {
          let site = this.siteMap.get(car.carId);
          site.marker.setPosition(point);
          site.label.setPosition(point);
          continue;
        }
        let icon = new BMapGL.Icon("/imgs/car.png", new BMapGL.Size(30, 20));
        let marker = new BMapGL.Marker(point, { icon });
        marker.setRotation(Number(car.direction));
        let label = new BMapGL.Label(
          `
          <div style="background: rgba(0, 0, 0, 0.5);display:flex;flex-wrap:wrap;width:320px;color:#fff;padding:10px;">
            <p style="width: 150px;">
              <span>车牌号：</span>
              <span>${car.carPlate}</span>
            </p>
            <p style="width: 150px;">
              <span>司机：</span>
              <span>${car.carTelName}</span>
            </p>
            <p style="width: 150px;">
              <span>电话：</span>
              <span>${car.carTalPhone}</span>
            </p>
            <p style="width: 150px;">
              <span>速度：</span>
              <span>${car.imeiStatus == 0 ? 0 : car.speed}km/h</span>
            </p>
            <p style="width: 300px;">
              <span>经纬度：</span>
              <span>${car.lng},${car.lat}</span>
            </p>
          </div>
        `,
          { position: point, offset: new BMapGL.Size(-160, -90) }
        );
        label.setStyle({
          border: "none",
          background: "none",
        });
        label.hide();
        this.map.addOverlay(label);
        marker.addEventListener("mouseover", () => {
          label.show();
        });
        marker.addEventListener("mouseout", () => {
          label.hide();
        });
        this.map.addOverlay(marker);
        this.siteMap.set(car.carId, { marker, label });
      }
    },

    cutCar(car) {
      this.searchShow = true;
      this.currentCar = car;
    },

    search() {
      if (!this.beginTime) {
        this.$Message.warning("请选择开始时间");
        return;
      }
      if (!this.endTime) {
        this.$Message.warning("请选择结束时间");
        return;
      }
      if (moment(this.beginTime).isAfter(moment(this.endTime))) {
        this.$Message.warning("结束时间不能小于开始时间");
        return;
      }
      let beginTime = moment(this.beginTime).format("YYYY-MM-DD HH:mm:ss");
      let endTime = moment(this.endTime).format("YYYY-MM-DD");
      this.$post(this.$api.TRAJECTORY, {
        imei: this.currentCar.imei,
        beginTime,
        endTime: endTime+' 23:59:59',
      }).then((res) => {
        console.log(res);
        this.trajectoryPoints = res;
        this.drawTrajectory(res);
      });
    },
    drawTrajectory(tracks) {
      if (this.trajectory) {
        this.map.removeOverlay(this.trajectory);
        this.trajectory = null;
      }
      if (this.startMarker) {
        this.map.removeOverlay(this.startMarker);
        this.startMarker = null;
      }
      if (this.endMarker) {
        this.map.removeOverlay(this.endMarker);
        this.endMarker = null;
      }
      if (!tracks || tracks.length == 0) return;
      this.hideCar();
      let points = [];
      for (let i = 0; i < tracks.length; i++) {
        let temp = tracks[i];
        points.push(new BMapGL.Point(temp.lng, temp.lat));
      }
      this.map.flyTo(points[points.length - 1], 13);
      let startIcon = new BMapGL.Icon(
        "/imgs/map/start.png",
        new BMapGL.Size(25, 30)
      );
      let endIcon = new BMapGL.Icon(
        "/imgs/map/end.png",
        new BMapGL.Size(25, 30)
      );
      this.startMarker = new BMapGL.Marker(points[0], {
        icon: startIcon,
        offset: new BMapGL.Size(0, -15),
      });
      this.endMarker = new BMapGL.Marker(points[points.length - 1], {
        icon: endIcon,
        offset: new BMapGL.Size(0, -15),
      });
      this.map.addOverlay(this.startMarker);
      this.map.addOverlay(this.endMarker);
      this.trajectory = new BMapGL.Polyline(points, {
        strokeColor: "green",
        strokeWeight: 8,
        strokeOpacity: 1,
      });
      this.map.addOverlay(this.trajectory);
    },
    start() {
      this.trackAni = new BMapGLLib.LuShu(this.map, this.trajectory.getPath(), {
        autoView: true,
        icon: new BMapGL.Icon("/imgs/car.png", new BMapGL.Size(30, 50), {
          imageOffset: new BMapGL.Size(0, -20),
        }),
        speed: 500,
        enableRotation: true,
        points: this.trajectoryPoints,
        carPlate: this.currentCar.carPlate,
      });
      this.trackAni.start();
    },
    playAni() {
      if (this.trackAni) {
        this.trackAni.start();
      } else {
        this.start();
      }
    },
    pauseAni() {
      this.trackAni.pause();
    },
    hideCar() {
      for (let value of this.siteMap.values()) {
        value.marker.hide();
      }
    },
    showCar() {
      for (let value of this.siteMap.values()) {
        value.marker.show();
      }
    },
    back() {
      this.map.flyTo(
        new BMapGL.Point(105.52314042411466, 29.475651579673745),
        11
      );
      this.map.removeOverlay(this.trajectory);
      this.trajectory = null;
      this.showCar();
      this.map.removeOverlay(this.startMarker);
      this.map.removeOverlay(this.endMarker);
      if (!this.trackAni) return;
      this.trackAni.removeCar();
      this.trackAni = null;
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>

<style lang="less" scoped>
.transport-page {
  width: 100%;
  height: 100%;
  position: relative;
  .map {
    width: 100%;
    height: 100%;
  }
  .date-box {
    position: absolute;
    top: 0;
    left: 260px;
    padding: 10px;
    background: #fff;
    z-index: 10;
  }
}
</style>