<template>
  <div class="left">
    <div class="search-box">
      <Input placeholder="输入车牌号">
        <Icon color="#000" type="md-search" slot="suffix" />
      </Input>
    </div>

    <div class="entity-item-list-box">
      <scrollBox>
        <div class="entity-list">
          <div
            @click="checkItem(car)"
            v-for="car in cars"
            :class="[
              'entity-item',
              current && current.carId === car.carId ? 'current-item' : '',
            ]"
          >
            <div class="entity-name-row">
              <span class="entity-name">{{ car.carPlate }}</span>
              <span
                :class="[
                  'entity-status',
                  car.status == 0 ? 'offline-class' : 'online-class',
                ]"
              >
                {{ car.carStatus }}
              </span>
            </div>
            <div class="driver-info-row">
              <span class="driver-name">{{ car.carTelName }}</span>
              <span class="driver-phone">{{ car.carTalPhone }}</span>
              <span class="pic-box">
                <img :src="phoneUrl" />
              </span>
            </div>
          </div>
        </div>
      </scrollBox>
    </div>
  </div>
</template>

<script>
import phoneUrl from "@/assets/imgs/phone.png";
import scrollBox from "@/components/scrollBox";
export default {
  name: "",
  components: {
    scrollBox,
  },
  data() {
    return {
      phoneUrl,
      cars: [],
      current: null,
      timer: null
    };
  },
  methods: {
    getList() {
      this.$post(this.$api.TRANSPORT.CAR_STATUS).then((res) => {
        // console.log(res);
        if (!res || !Array.isArray(res)) {
          this.cars = [];
          return;
        }
        this.cars = res.sort((a, b) => b.status - a.status);
        this.$emit("cars-location", this.cars);
      });
    },
    checkItem(car) {
      this.current = car;
      this.$emit('cut-car', car)
    },
  },
  mounted() {
    this.getList();
    this.timer = setInterval(() => {
      this.getList()
    }, 60000)
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  }
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>