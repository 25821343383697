var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"search-box"},[_c('Input',{attrs:{"placeholder":"输入车牌号"}},[_c('Icon',{attrs:{"slot":"suffix","color":"#000","type":"md-search"},slot:"suffix"})],1)],1),_c('div',{staticClass:"entity-item-list-box"},[_c('scrollBox',[_c('div',{staticClass:"entity-list"},_vm._l((_vm.cars),function(car){return _c('div',{class:[
            'entity-item',
            _vm.current && _vm.current.carId === car.carId ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.checkItem(car)}}},[_c('div',{staticClass:"entity-name-row"},[_c('span',{staticClass:"entity-name"},[_vm._v(_vm._s(car.carPlate))]),_c('span',{class:[
                'entity-status',
                car.status == 0 ? 'offline-class' : 'online-class',
              ]},[_vm._v(" "+_vm._s(car.carStatus)+" ")])]),_c('div',{staticClass:"driver-info-row"},[_c('span',{staticClass:"driver-name"},[_vm._v(_vm._s(car.carTelName))]),_c('span',{staticClass:"driver-phone"},[_vm._v(_vm._s(car.carTalPhone))]),_c('span',{staticClass:"pic-box"},[_c('img',{attrs:{"src":_vm.phoneUrl}})])])])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }